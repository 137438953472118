
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

function Slider() {
    return(
      <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
        <SwiperSlide>
          <section className='hero'>
            <div className='container'>
              <div className='row hero__row'>
                <div className='hero__content'>
                  <h1 className='hero__title'>Слухай Українське</h1>
                  <p className='hero__subtitle'>Більше 1000 підбірок та плейлистів. Більше 1000 підбірок та плейлистів. Більше 1000 підбірок та плейлистів.</p>
                  <button className='btn btn_primary btn_larger-size btn_icon btn_icon-hero'><span>Створити аккаунт</span></button>
                </div>
                <div className='hero__img'>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/hero-example.jpg`}  />
                </div>
              </div>
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className='hero'>
            <div className='container'>
              <div className='row hero__row'>
                <div className='hero__content'>
                  <h1 className='hero__title'>Слухай Українське</h1>
                  <p className='hero__subtitle'>Більше 1000 підбірок та плейлистів. Більше 1000 підбірок та плейлистів. Більше 1000 підбірок та плейлистів.</p>
                  <button className='btn btn_primary btn_larger-size btn_icon btn_icon-hero'><span>Створити аккаунт</span></button>
                </div>
                <div className='hero__img'>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/hero-example.jpg`}  />
                </div>
              </div>
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className='hero'>
            <div className='container'>
              <div className='row hero__row'>
                <div className='hero__content'>
                  <h1 className='hero__title'>Слухай Українське</h1>
                  <p className='hero__subtitle'>Більше 1000 підбірок та плейлистів. Більше 1000 підбірок та плейлистів. Більше 1000 підбірок та плейлистів.</p>
                  <button className='btn btn_primary btn_larger-size btn_icon btn_icon-hero'><span>Створити аккаунт</span></button>
                </div>
                <div className='hero__img'>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/hero-example.jpg`}  />
                </div>
              </div>
            </div>
          </section>
        </SwiperSlide>
      </Swiper>
    )
  }


  export default Slider;