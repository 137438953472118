function FormLogin() {
    const formLoginHandler = () => {
        alert('Login Form has been submitted');
    }
    return(
        <form className="form">
            <fieldset className="form__fieldset">
                <label className="form__label">
                    <p>Email</p>
                    <input name="email" id="email" type="mail" className="form__input form__input-text" />
                </label>
            </fieldset>
            <fieldset className="form__fieldset">
                <label className="form__label">
                    <p>Пароль</p>
                    <input name="pass" id="pass" type="Password" className="form__input form__input-pass" />
                </label>
            </fieldset>
            <button onClick={formLoginHandler} type="submit" className="btn btn_primary btn_normal-size btn_pop-up">Вхід</button>
        </form>
    )
};

export default FormLogin;