function SingleCardCollection({title,count,coverImg}) {
    return(
      <div className='card card_collection'>
        <img className='card__img' src={coverImg}></img>
        <div className='card__content'>
          <h4 className='card__title'>{title}</h4>
          <span className='card__subtitle'>{count} Пісень</span>
          <div className='card__icons'>
            <a className="card__icon card__icon_youtube" href='#'></a>
            <a className="card__icon card__icon_spotify" href='#'></a>
            <a className="card__icon card__icon_apple" href='#'></a>
          </div>        
        </div>
      </div>
    )
  }

  export default SingleCardCollection;
