import SingleCardCollection from './SingleCardCollection';

function ListCardCollection() {
    return(
      <section className='fp-section fp-section_collections'>
        {/* <SectionTitle title="Найкращі колекції пісень" btn="collection_btn"></SectionTitle> */}
        <h3 className="fp-section__title">Найкращі колекції пісень</h3>
        <button className='btn btn_inverted btn_normal-size fp-section__link' data-attr="collection_btn">Дивитись всі</button>
        <SingleCardCollection title='Пісні для шумної вечірки' count='22' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_1.png`}/>
        <SingleCardCollection title='Назад у 2000' count='26' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_4.png`}/>
        <SingleCardCollection title='Перші спроби на гітарі' count='44' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_3.png`}/>
        <SingleCardCollection title='Антитіла Найкраще' count='44' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_2.png`}/>
        <SingleCardCollection title='Антитіла Найкраще' count='44' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_2.png`}/>
        <SingleCardCollection title='Антитіла Найкраще' count='44' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_2.png`}/>
      </section>
    )
  }

  export default ListCardCollection;