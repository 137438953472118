import SingleCardMain from './SingleCardMain';

function ListCardMain({type}) {
    if(type === 'main') {
      var sectionType = 'fp-section fp-section_main';
    }
    else if(type === 'aside'){
      var sectionType = 'fp-section fp-section_aside';
    }
    return(
      <section className={sectionType} >
        {/* <SectionTitle title="Рекомендації для тебе" btn="recomendation_btn"></SectionTitle> */}
        <h3 className="fp-section__title">Рекомендації для тебе</h3>
        <button className='btn btn_inverted btn_normal-size fp-section__link' data-attr="recomendation_btn">Дивитись всі</button>
        <SingleCardMain title='Антитіла' subtitle='TDME' viewsCount='2245' likesCount='124' isNew='true' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_2.png`}/>
        <hr className='divider' />
        <SingleCardMain title='Антитіла' subtitle='TDME' viewsCount='2245' likesCount='124' isNew='false' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_2.png`}/>
        <hr className='divider' />
        <SingleCardMain title='Антитіла' subtitle='TDME' viewsCount='2245' likesCount='124' isNew='true' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_2.png`}/>
        <hr className='divider' />
        <SingleCardMain title='Антитіла' subtitle='TDME' viewsCount='2245' likesCount='124' isNew='false' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_2.png`}/>
        <hr className='divider' />
        <SingleCardMain title='Антитіла' subtitle='TDME' viewsCount='2245' likesCount='124' isNew='true' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_2.png`}/>
        <hr className='divider' />
        <SingleCardMain title='Антитіла' subtitle='TDME' viewsCount='2245' likesCount='124' isNew='false' coverImg={`${process.env.PUBLIC_URL}/assets/images/card_2.png`}/>
      </section>
    )
  }

  export default ListCardMain;