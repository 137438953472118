import FormLogin from "./FormLogIn";
import FormRegister from "./FormRegister";

function PopUp() {
    const showPopUpLogin = true;
    const showPopUpRegister = true;
    if(showPopUpLogin) {
        return(
            <div className="popup-shadow">
                <div className="popup">
                    <button className="popup__close">
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.410742 1.07354C0.736179 0.754785 1.26382 0.754785 1.58925 1.07354L6 5.39365L10.4107 1.07353C10.7362 0.754785 11.2638 0.754785 11.5893 1.07353C11.9147 1.39228 11.9147 1.90908 11.5893 2.22783L7.17851 6.54794L11.5893 10.8681C11.9147 11.1868 11.9147 11.7036 11.5893 12.0223C11.2638 12.3411 10.7362 12.3411 10.4107 12.0223L6 7.70224L1.58925 12.0223C1.26382 12.3411 0.736179 12.3411 0.410742 12.0223C0.085305 11.7036 0.085305 11.1868 0.410742 10.8681L4.82149 6.54794L0.410742 2.22783C0.0853049 1.90908 0.0853049 1.39228 0.410742 1.07354Z" fill="white"/>
                        </svg>
                    </button>
                    <div className="popup__content">
                        <h3 className="popup__title">Вхід</h3>
                        <p>Використати обліковий запис Google</p>
                        <button className="btn btn_bordered btn_full-width btn_larger-size">Google</button>
                        <hr className="popup__divider"></hr>
                        <p>Або використайте Email</p>
                        <FormLogin />
                    </div>
                </div>
            </div>
        )
    }
    else if(showPopUpRegister) {
        return(
            <div className="popup-shadow">
                <div className="popup">
                    <button className="popup__close">
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.410742 1.07354C0.736179 0.754785 1.26382 0.754785 1.58925 1.07354L6 5.39365L10.4107 1.07353C10.7362 0.754785 11.2638 0.754785 11.5893 1.07353C11.9147 1.39228 11.9147 1.90908 11.5893 2.22783L7.17851 6.54794L11.5893 10.8681C11.9147 11.1868 11.9147 11.7036 11.5893 12.0223C11.2638 12.3411 10.7362 12.3411 10.4107 12.0223L6 7.70224L1.58925 12.0223C1.26382 12.3411 0.736179 12.3411 0.410742 12.0223C0.085305 11.7036 0.085305 11.1868 0.410742 10.8681L4.82149 6.54794L0.410742 2.22783C0.0853049 1.90908 0.0853049 1.39228 0.410742 1.07354Z" fill="white"/>
                        </svg>
                    </button>
                    <div className="popup__content">
                        <h3 className="popup__title">Новий аккаунт</h3>
                        <p>Якщо у вас вже є аккаунт просто <a href="#">увійдіть</a></p>
                        <p>Використати обліковий запис Google</p>
                        <button className="btn btn_bordered btn_full-width btn_larger-size">Google</button>
                        <hr className="popup__divider"></hr>
                        <p>Або використайте Email</p>
                        <FormRegister />
                    </div>
                </div>
            </div>
        )
    }

}

export default PopUp;