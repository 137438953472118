import SingleCardNovelty from './SingleCardNovelty';

function ListCardNovelty() {
    return(
      <section className='fp-section fp-section_novelties'>
        {/* <SectionTitle title="Новинки" btn="novelties_btn"></SectionTitle> */}
        <h3 className="fp-section__title">Новинки</h3>
        <button className='btn btn_inverted btn_normal-size fp-section__link' data-attr="novelties_btn">Дивитись всі</button>
        <SingleCardNovelty title="Бумбокс" subtitle="Ким ми були" coverImg={`${process.env.PUBLIC_URL}/assets/images/card_1-big.jpg`}/>
        <SingleCardNovelty title="Бумбокс" subtitle="Гайки з Ямайки" coverImg={`${process.env.PUBLIC_URL}/assets/images/card_1-big.jpg`}/>
        <SingleCardNovelty title="Бумбокс" subtitle="Рожеві сиропи" coverImg={`${process.env.PUBLIC_URL}/assets/images/card_1-big.jpg`}/>
        <SingleCardNovelty title="Бумбокс" subtitle="Твій на 100%" coverImg={`${process.env.PUBLIC_URL}/assets/images/card_1-big.jpg`}/>
      </section>
    )
  }

  export default ListCardNovelty;