import MobileNavButton from "./MobileNavButton";
import Logo from "./Logo";
import LogIn from "./LogIn";
import Search from "./Search";
import Navigation from "./Navigation";

function Header() {
    return (
      <header className='header'>
        <MobileNavButton />
        <Logo />
        <div className='header-divider'></div>
        <Search />
        <Navigation />
        <LogIn />
      </header>
    );
  }

  export default Header;