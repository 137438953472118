import Logo from "./Logo";

function Footer() {
    return(
      <footer className='footer'>
        <nav className='nav nav_footer'>
          <ul className='nav__list nav__list_footer'>
            <li className='nav__item'>
              <a className='nav__link link' href='#'>Відгуки та пропозиції</a>
            </li>
            <li className='nav__item'>
              <a className='nav__link link' href='#'>Політика конфіденційності</a>
            </li>
            <li className='nav__item'>
              <a className='nav__link link' href='#'>Співпраця</a>
            </li>
          </ul>
        </nav>
        <div className='footer-main'>
          <Logo></Logo>
          <ul className='footer-social'>
            <li className='footer-social__item'>
              <a className="footer-social__link footer-social__link_instagram" href="#"></a>
            </li>
            <li className='footer-social__item'>
              <a className="footer-social__link footer-social__link_tt" href="#"></a>
            </li>
            <li className='footer-social__item'>
              <a className="footer-social__link footer-social__link_yt" href="#"></a>
            </li>
            <li className='footer-social__item'>
              <a className="footer-social__link footer-social__link_tg" href="#"></a>
            </li>
            <li className='footer-social__item'>
              <a className="footer-social__link footer-social__link_spot" href="#"></a>
            </li>
          </ul>
        </div>
        <p className='copyright'>© 2022 bezodnyachords.com</p>
      </footer>
    )
  }


  export default Footer;