function Navigation(){
    return(
      <nav className='nav'>
        <ul className='nav__list'>
          <li className='nav__item'><a className='nav__link link' href="#main">Топ пісень</a></li>
          <li className='nav__item'><a className='nav__link link' href="#all-chords">Генератор акордів</a></li>
          <li className='nav__item'><a className='nav__link link' href="#add-chords">Додати акорди</a></li>
        </ul>
      </nav>
    )
  }

  export default Navigation;