function SingleCardNovelty({title,subtitle,coverImg}) {
    return(
      <div className='card card_novelty'>
        <img className='card__img' src={coverImg}></img>
        <span className='new-sign new-sign_secondary new-sign_position-absolute'>New</span>
        <div className='card__content'>
          <h4 className='card__title'>{title}</h4>
          <span className='card__subtitle'>{subtitle}</span>      
        </div>
      </div>
    )
  }


  export default SingleCardNovelty;