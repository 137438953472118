
// Copmponents and styles
import './inc/style/main.scss';

import Header from "./components/alwaysonpage/Header";
import Breadcrumbs from "./components/Breadcrumbs";
import Slider from './components/Slider';
import HeroAuthor from './components/HeroAuthor';
import ListCardCollection from './components/cards/ListCardCollection';
import ListCardNovelty from './components/cards/ListCardNovelty';
import ListCardMain from './components/cards/ListCardMain';
import ChordPage from './components/chords-and-songs/ChordPage';
import AddChordsPage from './components/chords-and-songs/AddChordsPage';
import CommentsList from "./components/comments/CommentsList";
import CommentForm from "./components/comments/CommentForm";
import Footer from "./components/alwaysonpage/Footer";



function Hero() {
  const isSlider = true;
  const isAuthorPage = false;
  if(isSlider) {
    return (
      <section className='hero'>
        <Slider />
      </section>
    )
  }
  else if (isAuthorPage) {
    return (
      <HeroAuthor />
    )
  }
}

function FrontPageMainSections() {
    return(
      <main>
        <div className='container fp-sections-wrapper'>
          <ListCardCollection />
          <ListCardNovelty />
          <ListCardMain type="aside" />
        </div>
      </main>
    )
}


function SectionMain() {
  return(
    <div className='container fp-sections-wrapper '>
      <ListCardMain type="main"/>
    </div>
  )
}


function App() {
  return (
    <>
      <Header />
      {/* <Breadcrumbs/> */}
      <Hero />
      <FrontPageMainSections />
      {/* <SectionMain /> */}
      {/* <ChordPage /> */}
      {/* <AddChordsPage /> */}
      <Footer />
    </>
  );
}

export default App;
