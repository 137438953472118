function SongRating({viewsCount, likesCount}) {
    return(
      <ul className='song-rating'>
        <li className="song-rating__item">
          <span className='song-rating__count'>{viewsCount}</span>
          <button className="song-rating__icon song-rating__icon_view"></button>
        </li>
        <li className="song-rating__item">
          <span className='song-rating__count'>{likesCount}</span>
          <button className="song-rating__icon song-rating__icon_like"></button>
        </li>
      </ul>
    )
  }

  export default SongRating;