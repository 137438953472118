import SongRating from "../chords-and-songs/SongRating";

function SingleCardMain({title, subtitle, coverImg, viewsCount, likesCount, isNew}) {
    return(
      <div className='card card_main'>
        <img className='card__img' src={coverImg}></img>
        <div className='card__content'>
          {isNew === 'true'
            ? <span className='new-sign new-sign_primary new-sign_position-absolute new-sign_position-absolute-right'>New</span>
            : <span></span>
          }
          <h4 className='card__title'>{title}</h4>
          <span className='card__subtitle'>{subtitle}</span>
          <SongRating viewsCount={viewsCount} likesCount={likesCount} />
        </div>
      </div>
    )
  }

  export default SingleCardMain;
