import { useState } from "react";

import PopUp from "./PopUp";


function LogIn(){
    const [showPopUp,setShowPopUp] = useState(false);
    const popupHandler = () => {
      return setShowPopUp(!showPopUp);
    }
    return(
      <div className="login">
        <button onClick={popupHandler} className="login__btn btn btn_primary btn_normal-size register">Новий акаунт</button>
        <button onClick={popupHandler} className="login__btn btn btn_inverted btn_normal-size sign-in">Вхід</button>
        {showPopUp && <PopUp />}
      </div>
    )
  }

  export default LogIn;