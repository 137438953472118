function MobileNavButton() {
    return(
      <button className='mobile-nav'>
        <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.66666 0.666504C0.930282 0.666504 0.333328 1.26346 0.333328 1.99984C0.333328 2.73622 0.930282 3.33317 1.66666 3.33317H20.3333C21.0697 3.33317 21.6667 2.73622 21.6667 1.99984C21.6667 1.26346 21.0697 0.666504 20.3333 0.666504H1.66666Z" fill="#777E91"/>
          <path d="M1.66666 8.6665C0.930282 8.6665 0.333328 9.26346 0.333328 9.99984C0.333328 10.7362 0.930282 11.3332 1.66666 11.3332H20.3333C21.0697 11.3332 21.6667 10.7362 21.6667 9.99984C21.6667 9.26346 21.0697 8.6665 20.3333 8.6665H1.66666Z" fill="#777E91"/>
        </svg>
      </button>
    )
  }

  export default MobileNavButton;